<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "nǐ",
          hanzi: "你",
          english: "you",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-ni-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "hǎo",
          hanzi: "好",
          english: "good",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-hao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "nǐ hǎo",
          hanzi: "你好",
          english: "Hello",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-nihao-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "wǒ",
          hanzi: "我",
          english: "I; me",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-wo-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "shì",
          hanzi: "是",
          english: "am / is / are",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-shi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "six",
          isShowImg: "true",
          currentNum: 6,
          pinyin: "Wǒ shì……",
          hanzi: "我是……",
          english: "I am...",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-woshi-bubble.svg`),
          blue: true,
          longtext: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
